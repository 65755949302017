import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import request from "../../request/request";
import { validate } from "../../utils/validationUtils";
import FallingLinesLoader from "../Loader/FallingLinesLoader";
import Success from "../Messages/Success";

interface RegisterData {
  name: string,
  email: string,
  password: string,
  confirmPassword: string
}

interface ValidationError {
  errorName?: string,
  errorEmail?: string,
  errorPassword?: string,
  errorRePassword?: string,
}

const Registration: React.FC = () => {

  const [data, setData] = useState<RegisterData>({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  let [error, setError] = useState<ValidationError>({
    errorName: "",
    errorEmail: "",
    errorPassword: "",
    errorRePassword: ""
  });

  const [regerror, setRegerror] = useState<string | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData((prevData) => ({ ...prevData, [event.target.name]: event.target.value }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();
    setLoader(true);

    try {

      const validationError: ValidationError = validate(data);      

      if (Object.keys(validationError).length === 0) {

        const response = await request.register(data);

        setLoader(false);

        if (response.data.status === "ok") {
          setSuccess(true);
          navigate("/login");
        } else if (response.data.status === "error") {
          setRegerror(response.data.message);

          setTimeout(() => {
            setRegerror(null);
          }, 4000);
        }
      } else {
        setError(validationError);
      }
    } catch (err) {
      console.error("Registration failed:", err);
    } finally{
      setLoader(false);
    }
  };

  useEffect(() => {
    // Focus the input element on mount
    if(!inputRef.current){
      return
    }

    inputRef.current.focus();
  }, []);


  return (
    <>
      <h1 className="text-3xl font-bold text-center mb-8  mt-8 text-gray-800">
        Create Account
      </h1>
      <div className="container mx-auto max-w-md bg-white p-8 rounded shadow-md">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="username" className="text-sm font-semibold text-gray-600">
              Name
            </label>
            <input
              name="name"
              type="text"
              placeholder="Enter Your Name"
              onChange={handleChange}
              className="mt-1 p-2 w-full border rounded focus:outline-none focus:border-green-500"
              ref={inputRef}
            />
            <span className="text-xs text-red-500">{error.errorName}</span>
          </div>

          <div className="mb-4">
            <label htmlFor="email" className="text-sm font-semibold text-gray-600">
              Email-Id
            </label>
            <input
              name="email"
              type="email"
              placeholder="Enter Your Email-Id"
              onChange={handleChange}
              className="mt-1 p-2 w-full border rounded focus:outline-none focus:border-green-500"
            />
            <span className="text-xs text-red-500">{error.errorEmail}</span>
          </div>

          <div className="mb-4">
            <label htmlFor="password" className="text-sm font-semibold text-gray-600">
              Password
            </label>
            <input
              name="password"
              type="password"
              placeholder="Enter Your Password"
              onChange={handleChange}
              className="mt-1 p-2 w-full border rounded focus:outline-none focus:border-green-500"
            />
            <span className="text-xs text-red-500">{error.errorPassword}</span>
          </div>

          <div className="mb-4">
            <label htmlFor="repass" className="text-sm font-semibold text-gray-600">
              Confirm Password
            </label>
            <input
              name="confirmPassword"
              type="password"
              placeholder="Confirm your password"
              onChange={handleChange}
              className="mt-1 p-2 w-full border rounded focus:outline-none focus:border-green-500"
            />
            <span className="text-xs text-red-500">{error.errorRePassword}</span>
          </div>

          <p className="text-gray-600 text-sm mb-4">
            Already have an account? <Link to="/login" className="text-green-500">Sign In</Link>
          </p>  

          {regerror !== null ? <div className="bg-red-100 text-red-500 p-2 mb-4">{regerror}</div> : null}

          {loader && <FallingLinesLoader />}

         {success && <Success message="Registration Successful"/> }

          <button
            type="submit"
            className="w-full bg-green-700 text-white p-2 rounded hover:bg-green-600 focus:outline-none focus:shadow-outline-green"
          >
            Register
          </button>
        </form>
      </div>
    </>
  );
};

export default Registration;
