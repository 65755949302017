import React from 'react'

interface SuccessProp {
    message: string
}

const Success: React.FC<SuccessProp> = ({message}) => {

  return (
    <div className="bg-green-500 text-white p-2 mb-4 rounded">
           {message}
        </div>
  )
}

export default Success;