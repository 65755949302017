import React, {useEffect} from 'react'
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

interface  ProtectedRoutesUserProps {
    children?: React.ReactNode;
}

const ProtectedRoutesUser: React.FC<ProtectedRoutesUserProps> = ({children}) => {

        const {isLoggedIn} = useAuth();
        const navigate = useNavigate();

        useEffect(()=>{

            if(!isLoggedIn){
                navigate('/login', {replace: true});
            }else{
                navigate('/dashboard', {replace: true})
            }

        },[isLoggedIn, navigate])

  return <>{children}</>
  
}

export default ProtectedRoutesUser;