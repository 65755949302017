import React from 'react'
import './style.css'
import leftImg from './left.png'
import rightImg from './right.png'
import { useNavigate } from 'react-router-dom'
import { LogIn } from 'lucide-react'


const HeroSection: React.FC = () => {

  const navigate = useNavigate();

  const handleGetStarted = () => {

    navigate('/login');
  }

  return (
    <div className='hero-section mt-5 mb-5'>
      <div className='hero-title'>
        Secure Your Backlinks, Monitor, and Get Alerts
      </div>
      <div className='hero-para-img'>

        <div className='para-left-img'>
          <img src={leftImg} />
        </div>

        <div className='text-slate-800 text-'>

          Effortlessly monitor and protect your backlinks with our platform. We notify you if any link is deleted, eliminating the hassle of link maintenance. Build connections with confidence.
        </div>

        <div className='para-right-img'>
          <img src={rightImg} />
        </div>

      </div>
      <div className='get-started-btn-hero' onClick={handleGetStarted}>
        <div className='flex gap-2'> <span>Get Started</span> <LogIn /> </div>
      </div>

    </div>
  )
}

export default HeroSection;