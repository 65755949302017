import React from 'react'
import { FallingLines } from 'react-loader-spinner'

const FallingLinesLoader: React.FC = () => {


    return (

        <div className='flex justify-center'>
            <FallingLines
                color="#4fa94d"
                width="50"
                visible={true}
            />
        </div>
    )
}

export default FallingLinesLoader