
import React from 'react';
import './style.css'

interface TruncateStringProps{
    originalString: string,
    maxLength: number
}

const TruncateString: React.FC<TruncateStringProps> = ({ originalString, maxLength }) => {
    
    const truncatedString = originalString.length > maxLength
        ? originalString.slice(0, maxLength) + '...'
        : originalString;

    return (
        <div className="truncate-container">
            <p className='truncate-text'>{truncatedString}</p>
        </div>
    );
};

export default TruncateString;
