import { createContext, useCallback, useContext, useState } from "react";

interface BacklinksInterface {
    backlinksArray: { keyword: string; found: boolean }[];
    sponsoredLink: string;
  }

  interface BacklinksProviderProps {
    children: React.ReactNode;
  }

  interface BacklinksContextType {  
    _addBacklinks: (backlinksArray: { keyword: string; found: boolean }[], sponsoredLink: string) => void;
    backlinks: BacklinksInterface,
    _loaderToTrack: (boolean: boolean) => void,
    loader: boolean | undefined
  }
  
const BacklinksContext = createContext<BacklinksContextType | undefined>(undefined);

export const BacklinksProvider: React.FC<BacklinksProviderProps> = ({ children }) => {
    
    const [backlinks, setBacklinks] = useState<BacklinksInterface>({
        backlinksArray: [],
        sponsoredLink: ''
    });
    const [loader, setLoader] = useState<boolean | undefined>(undefined);

    const _addBacklinks = useCallback((backlinksArray: { keyword: string; found: boolean }[], sponsoredLink: string) => {
        setBacklinks({
            backlinksArray,
            sponsoredLink
        });
      }, []);

      const _loaderToTrack = (boolean: boolean | undefined) =>{
        setLoader(boolean);
      }


    return (

        <BacklinksContext.Provider value={{_addBacklinks, backlinks, _loaderToTrack, loader}}>
            {children}
        </BacklinksContext.Provider>
    )
    
}

export const useBacklinks = ():BacklinksContextType =>{

  const context = useContext(BacklinksContext);

  if (!context) {
    throw new Error("useBacklinks must be used within a BacklinksProvider");
  }
    return context;

};