// DeleteContext.js
import React, { createContext, useContext, useState } from 'react';

interface DeleteProviderProps{
  children: React.ReactNode,
}

interface DeleteContextTypes{

  deleteStatusText: string | null,
  setDeleteStatusText: React.Dispatch<React.SetStateAction<string | null>>
}

export const DeleteContext = createContext<DeleteContextTypes | undefined>(undefined);

export const DeleteProvider: React.FC<DeleteProviderProps>= ({ children }) => {
  const [deleteStatusText, setDeleteStatusText] = useState<string | null>(null);

  
  return (
    <DeleteContext.Provider value={{deleteStatusText, setDeleteStatusText}}>
      {children}
    </DeleteContext.Provider>
  );
};

export const useDeleteContext = () =>{

  const context = useContext(DeleteContext);

  if (!context) {
    throw new Error("useBacklinks must be used within a BacklinksProvider");
  }
    return context;

};