import React, { createContext, useEffect, useRef, useState } from 'react'
import './LinkTrackerSection.css'
import LinkPool from '../LinkPool/LinkPool';
import request from '../../request/request';
import {useDeleteContext } from '../../context/DeleteContext';
import { useAuth } from '../../context/AuthContext';
import LinkSubmitButton from '../Buttons/LinkSubmitButton';
import BacklinksDetail from '../BacklinksDetail/BacklinksDetail';
import { useBacklinks } from '../../context/BacklinksContext';
import FallingLinesLoader from '../Loader/FallingLinesLoader';
import Failure from '../Messages/Failure';
import Success from '../Messages/Success';


interface BacklinkList {
    value: string;
}

interface backLinkDataTypes {
    sponsoredLink: string | null,
    backlinks: string[]
}


export const statusContext = createContext<boolean>(false);

const LinkTrackerSection: React.FC = ()=>{

    const [sponsoredPost, setSponsoredPost] = useState<string | null>(null);
    const [backlinksList, setBacklinksList] = useState<BacklinkList[]>([{ value: '' }]);
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [loader_, setLoader_] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement | null>(null);


    const { _updateCredits, _logout } = useAuth();
    const { deleteStatusText } = useDeleteContext();

    const { backlinks, loader } = useBacklinks();

    // Check if backlinks has data
    const hasData = backlinks.backlinksArray.length > 0 || backlinks.sponsoredLink !== '';

        

    const handleSponsoredBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value.trim();
            setSponsoredPost(inputValue);
    }

    const handleBanklinksTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const inputValue = event.target.value;
        const urls = inputValue.split(',').map(url => url.trim()).filter(url => url !== ''); // Split by commas, trim spaces, and remove empty strings
        setBacklinksList(urls.map(url => ({ value: url })));
    }

    const handleSubmitToPool = async () => {
        setError(null)
        setLoader_(true);

        if (sponsoredPost === null) {
            setError('Sponsored Post URL is required.');
            setLoader_(false);
            setTimeout(() => {
                setError("");
            }, 5000);
            return;
        }

        console.log("Backlinks List Length:", backlinksList.length);

        
        if (backlinksList.length === 0) {
            setError('Backlinks are required.');
            setLoader_(false);
            setTimeout(() => {
                setError("");
            }, 5000);
            return;
        }

        if (sponsoredPost && sponsoredPost.length > 200) {
            setError("Sponsored Post length must be less than or equal to 200 characters");
            setLoader_(false);
            setTimeout(() => {
                setError("");
            }, 5000);
            return
        }

        if (backlinksList.length > 10) {
            setError('Ony 10 backlinks per sponsored post are allowed');
            setLoader_(false);
            setTimeout(() => {
                setError("");
            }, 5000);
            return;
        }

       

        const data: backLinkDataTypes = {

            sponsoredLink: sponsoredPost,
            backlinks: backlinksList.map(field => field.value)
        };

        try {

            const response = await request.addLinks(data);

            if (response.status === 401) {

                _logout();

            } else if (response && response.data && response.data.status === 'ok') {
                setSuccess(true);
                setLoader_(false);
                _updateCredits(response.data.total_credits);
                setTimeout(() => {
                    setSuccess(false);
                }, 5000);

            } else {

                setError(response.data.error)
                setTimeout(() => {
                    setError(null);
                }, 5000);
            }

        } catch (error) {

            // Log the error for debugging purposes
            console.error('An unexpected error occurred during the request:', error);

            // Handle the error gracefully
            setError('An unexpected error occurred');

            setTimeout(() => {
                setError(null);
            }, 5000);

        } finally {
            setLoader_(false);
        }
    }

    useEffect(()=>{

        if(!inputRef.current){
            return
        }

        inputRef.current.focus();

        console.log("rendering linkTrackerSection");


    },[])

    return (
        <div className='overall-main-section'>
            <div className='forms-and-btn-container'>


                <div className="flex flex-col md:flex-col space-y-4  md:space-y-0">

                    {/* sponsored Box */}
                    <div className="flex flex-col items-left ">
                        <label className="text-md font-semibold text-gray-500 mb-1">Sponsored Post</label>
                        <input
                            className="w-full border border-gray-300 p-2 rounded mb-3 focus:outline-none focus:border-green-500"
                            type="text"
                            placeholder="https://www.example.com/"
                            onChange={(e) => handleSponsoredBox(e)}
                            ref={inputRef}
                        />


                    </div>

                    {/* Backlinks */}
                    <div className="flex flex-col items-left">
                        <label className="text-md font-semibold text-gray-500 mb-1">Backlinks</label>
                        <textarea
                            className="w-full h-32 border border-gray-300 p-2 rounded focus:outline-none focus:border-green-500"
                            placeholder="Enter backlinks separated by commas"
                            onChange={(e) => handleBanklinksTextArea(e)}
                        />

                    </div>
                </div>

                <div className='submit-btn'>

                    <LinkSubmitButton children={handleSubmitToPool} />

                </div>

                <div className='p-1'>
                {loader_ && <FallingLinesLoader/>}
                {success && <Success message='Links added to a pool successfully!' />}
                {error && <Failure message={error} /> }
                {deleteStatusText && <Failure message={deleteStatusText} />}
                </div>

                
            </div>

            <statusContext.Provider value={success}>
                <LinkPool showCheckAvailability = {true}/>
            </statusContext.Provider>


            {
                loader ?
                    <FallingLinesLoader />
                    : hasData &&
                    <BacklinksDetail />
            }

        </div>
    )
}
export default LinkTrackerSection