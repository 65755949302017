import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import './LinkPool.css';
import request from '../../request/request';
import { statusContext } from '../LinkTracker/LinkTrackerSection';
import TruncateString from '../TruncateString/TruncateString';
import { useAuth } from '../../context/AuthContext';
import { Link2, MoreVertical } from 'lucide-react';
import FallingLinesLoader from '../Loader/FallingLinesLoader';
import RemoveLink from '../Buttons/RemoveButton';
import LinkAvailabilityDetails from '../Buttons/LinkAvailabilityDetails';


interface LinksData {
    backlinks: string[],
    sponsoredLink: string,
    user: string,
    _id: string
}

interface LinkPoolProps {
    showCheckAvailability: boolean;
}

interface MenuStates {
    [itemId: string]: boolean;
}

const LinkPool: React.FC<LinkPoolProps> = ({ showCheckAvailability }) => {

    const [links, setLinks] = useState<LinksData[]>([]);
    const [deleteStatus, setDeleteStatus] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [menuStates, setMenuStates] = useState<MenuStates>({});
    const [hoveredLinkId, setHoveredLinkId] = useState<string | null>(null);


    const handleMouseEnter = (itemId:string) => {
        
        setHoveredLinkId(itemId);
    };

    const handleMouseLeave = () => {
        setHoveredLinkId(null);
    };



    const status = useContext(statusContext);

    const { isLoggedIn, _logout } = useAuth();



    const getAllLinks = async () => {

        setIsLoading(true);

        try {

            const response = await request.getLinks();

            if (response.status === 401) {

                _logout();
            } else {

                setIsLoading(false);
                setLinks(response.data);

            }


        } catch (error) {
            console.error('Error fetching links:', error);
        }
    };

    useEffect(() => {

        console.log("linkpool rendering");

        getAllLinks();

    }, [status, deleteStatus, isLoggedIn]);


    const handleMenuToggle = (itemId: string) => {

        setMenuStates((prevMenuStates) => ({
            ...prevMenuStates,
            [itemId]: !prevMenuStates[itemId],
        }));
    };

    //pagination
    const itemsPerPage = 5; // Adjust the number of items per page


    const currentLinks = links && links.length > 0
        ? links.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
        : [];   

    

    return (

        <div className='all-links'>

            <div className='relative mb-2'>
                <p
                    className="absolute py-1 px-4 bg-emerald-500 text-white rounded-full text-xs font-semibold uppercase tracking-wide  transform -translate-y-1/2 ">
                    My Posts
                </p>

                <p
                    className="absolute py-1 px-4 bg-slate-700 text-white rounded-full text-xs font-semibold uppercase tracking-wide  transform -translate-y-1/2 ml-auto right-0">
                    Total Links - {links.length}
                </p>

            </div>

            {isLoading ? (

                <FallingLinesLoader />

            ) : links.length > 0 ? (

                currentLinks?.map((item) => (
                    <div className="wrapper" key={item._id}>

                        <div className='link-container' onMouseEnter={() => handleMouseEnter(item._id)} onMouseLeave={handleMouseLeave}>

                            <Link2 />
                            <div className='sponsored-link-single'>
                                <TruncateString originalString={item.sponsoredLink} maxLength={70} />
                            </div>

                            {
                                showCheckAvailability ? (
                                    <>
                                        <LinkAvailabilityDetails text={'Check Availability'} backlinks={item} />


                                        <div className='delete-btn-onshow'>

                                            {menuStates[item._id] && (

                                                <RemoveLink text={'Remove'} id={item._id} setDeleteStatus={setDeleteStatus} deleteStatus={deleteStatus} />
                                            )}

                                        </div>


                                        {hoveredLinkId === item._id &&
                                            <div className='verticle-ops' key={item._id}>


                                                <MoreVertical size={20} onClick={() => handleMenuToggle(item._id)} />

                                            </div>}
                                    </>
                                ) : null

                            }


                        </div>

                        <hr className='h-px bg-gray-200 border-0 dark:bg-gray-700' />
                    </div>
                ))
            ) : (
                <p>No links available</p>
            )}

            {/* Pagination buttons */}
            <div className='pagination'>
                <button
                    onClick={() => setCurrentPage(prevPage => Math.max(prevPage - 1, 1))}
                    disabled={currentPage === 1}
                    className='bg-green-600 hover:bg-green-700 text-white py-1 px-1 rounded '
                >
                    Previous
                </button>
                <span className='pagination-info'>{`Page ${currentPage}`}</span>
                <button
                    onClick={() => setCurrentPage(prevPage => Math.min(prevPage + 1, Math.ceil(links.length / itemsPerPage)))}
                    disabled={currentPage === Math.ceil(links.length / itemsPerPage)}
                    className='bg-green-600 hover:bg-green-700 text-white py-1 px-2 rounded'
                >
                    Next
                </button>
            </div>
        </div>
    );
}

export default memo(LinkPool);
