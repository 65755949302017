import React from 'react'

interface FailureProps {
    message: string
}

const Failure: React.FC<FailureProps> = ({message}) => {

  return (
    <div className="bg-red-500 text-white p-2 mb-4 rounded">
           {message}
        </div>
  )
}

export default Failure;