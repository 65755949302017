import './style.css';
import useNetworkStatus from '../../hooks/useNetwork';

const Notification: React.FC = () => {
  
  const isOnline = useNetworkStatus();
  
  console.log(isOnline);

  return (
    <div className={`notification ${isOnline ? '' : 'visible'}`}>
      Please check your network connection
    </div>
  );
};

export default Notification;
