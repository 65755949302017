import { BellRing, Mail, Mails, User } from 'lucide-react'
import { memo, useEffect, useState } from 'react'
import ToggleButton from '../Toggle/ToggleButton';
import request from '../../request/request';
import { useAuth } from '../../context/AuthContext';
import FallingLinesLoader from '../Loader/FallingLinesLoader';
import Success from '../Messages/Success';
import Failure from '../Messages/Failure';


interface UserData {
    email: string
    name: string
    token: string
    total_credits: number
    emailNotification: boolean
    automatedEmail: boolean
}
const AccountSettings: React.FC=() => {

    const { _login, username, email,emailNotification, automatedEmail } = useAuth();


    const [isChecked, setIsChecked] = useState(emailNotification);
    const [isCheckedAlert, setIsCheckedAlert] = useState(automatedEmail);
    const [loader, setLoader] = useState<boolean>(false);
    const [success, setSuccess] = useState('')
    const [error, setError] = useState('')


    const [updatedDetails, setUpdatedDetails] = useState({
        email: email,
        name: username,
        emailNotification: emailNotification,
        automatedEmail: automatedEmail
    })

   
    const handleToggle = () => {
        setIsChecked(!isChecked);

        setUpdatedDetails(prevState => ({
            ...prevState,
            emailNotification: !prevState.emailNotification
        }));
    };

    const handleToggleAlert = () => {
        setIsCheckedAlert(!isCheckedAlert)   

         setUpdatedDetails(prevState => ({
        ...prevState,
        automatedEmail: !prevState.automatedEmail
    }));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoader(true);

        try {

            const response = await request.updateProfile(updatedDetails);
            
            if (response.status === 200) {

                setLoader(false)

                setSuccess(response.data.message)

                setTimeout(() => {
                    setSuccess('')
                }, 4000);

                 _login(response.data)


            }else{

                setError(response.data.message)

                setTimeout(() => {
                    setError('')
                }, 4000);
            }


        } catch(error) {
            console.log("error");

        } finally {
            setLoader(false);
        }

    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value } = e.target;
        setUpdatedDetails(prevState => ({
            ...prevState!,
            [name]: value
        }));

    };

    console.log("account setting renders");
    
    
    return (
        <div>

            <div className=" mx-auto p-8 rounded">
                <h1 className="text-2xl text-left mb-1 text-gray-600 ">
                    ACCOUNT SETTINGS
                </h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="text-sm font-semibold text-gray-600">
                            <span className="flex"><User /> <p className='p-1'>Your Name</p> </span>
                        </label>
                        <input
                            name="name"
                            type="text"
                            value={updatedDetails.name}
                            placeholder="Enter Your Name"
                            onChange={handleChange}
                            className="mt-1 p-2 w-full border rounded focus:outline-none focus:border-green-500"
                        //   ref={inputRef}
                        />
                        {/* <span className="text-xs text-red-500">{error.errorName}</span> */}
                    </div>

                    <div className="mb-4">
                        <label className="text-sm font-semibold text-gray-600">
                            <span className="flex"><Mail /> <p className='p-1'>Your Email</p> </span>
                        </label>
                        <input
                            name="email"
                            type="text"
                            value={updatedDetails.email}
                            placeholder="Enter Your Name"
                            onChange={handleChange}
                            className="mt-1 p-2 w-full border rounded focus:outline-none focus:border-green-500"
                        //   ref={inputRef}
                        />
                        {/* <span className="text-xs text-red-500">{error.errorName}</span> */}
                    </div>

                    <div className="mb-4">

                        <label className="text-sm font-semibold text-gray-600">
                            <span className="flex"><BellRing /> <p className='p-1'>Email Notifications</p> </span>
                        </label>

                        <div className='mt-1 bg-white p-1 w-full border rounded focus:outline-none focus:border-green-500'>
                            <span className="flex mt-1 pl-1">  <div className='text-sm text-slate-500'>Weekly Notification?</div> <ToggleButton isChecked={isChecked} handleToggle={handleToggle} /> </span>
                        </div>

                    </div>

                    <div className="mb-4">

                        <label className="text-sm font-semibold text-gray-600">
                            <span className="flex"><Mails /> <p className='p-1'>Get Daily Bulk Check Alert</p> </span>
                        </label>

                        <div className='mt-1 bg-white p-1 w-full border rounded focus:outline-none focus:border-green-500'>
                            <span className="flex mt-1 pl-1"> <div className='text-sm text-slate-500'>Daily Backlink Check </div><ToggleButton isChecked={isCheckedAlert} handleToggle={handleToggleAlert} /></span>
                        </div>

                    </div>

                     {/* {regerror !== null ? <div className="bg-red-100 text-red-500 p-2 mb-4">{regerror}</div> : null}  */}
                    {loader && <FallingLinesLoader />} 
                    {success.length > 1 && <Success message={success} />}
                    {error.length > 1 && <Failure message={success} />}
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className="bg-green-700 text-white text-sm p-2 rounded hover:bg-green-600 focus:outline-none focus:shadow-outline-green"
                        >
                            Update Account
                        </button>
                    </div>


                </form>
            </div>
        </div>
    )
}

export default memo(AccountSettings)