import DashboardSection from './components/Dashboard/DashboardSection';
import { DeleteProvider } from './context/DeleteContext';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Registration from './components/Forms/Register';
import { AuthProvider } from './context/AuthContext';
import Login from './components/Forms/Login';
import NetworkStatus from './components/Network/NetworkStatus';
import { BacklinksProvider } from './context/BacklinksContext';
import ProtectedRoutesUser from './components/ProtectedRoutes/ProtectedRoutesUser';
import HomePage from './pages/Home/HomePage';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import { useEffect } from 'react';

const App: React.FC = () => {

  useEffect(() => {
    
    var Tawk_API: any = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/65e1a2b08d261e1b5f6732df/1hnsm70d7';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode?.insertBefore(s1, s0);
    })();


  }, [])


  return (
    <BacklinksProvider>
      <AuthProvider>
        <Router>
          <div className="flex flex-col min-h-screen">
            <Navbar />
            <NetworkStatus />
            <DeleteProvider>
              <Routes>

                <Route path="/dashboard" element={<ProtectedRoutesUser> <DashboardSection /> </ProtectedRoutesUser>} />
                <Route path="/register" element={<Registration />} />
                <Route path="/login" element={<ProtectedRoutesUser><Login /></ProtectedRoutesUser>} />
                <Route path="/" element={<HomePage />} />

              </Routes>
            </DeleteProvider>
            <Footer />
          </div>
        </Router>
      </AuthProvider>
    </BacklinksProvider>

  );
}

export default App;
