import React from 'react'
import { useAuth } from '../../../context/AuthContext'
import { CheckCircle2 } from 'lucide-react';

interface CardProps {
    price: number,
    credits: number,
    duration: string,
    plan: string
}

const Card: React.FC<CardProps>= ({ price, credits, duration, plan }) => {

    const {isLoggedIn} = useAuth();

    return (
        <div>

            <div className="mt-16 container space-y-12  ">

                <div className="relative p-5  border border-gray-200 rounded-2xl shadow-sm flex flex-col">
                    <div className="flex-1">
                        <h3 className="text-xl font-semibold ">{plan}</h3>

                         { price === 4.99 ?
                        <p
                            className="absolute top-0 py-1.5 px-4 bg-emerald-500 text-white rounded-full text-xs font-semibold uppercase tracking-wide  transform -translate-y-1/2">
                            Most popular</p> : null
                            }

                        <p className="mt-4 flex items-baseline ">
                            <span className="text-5xl font-extrabold tracking-tight">${price}</span><span className="ml-1 text-xl font-semibold">/{duration}</span>
                        </p>
                        <p className="mt-6">Get ready to add your links to pool</p>
                        <ul className="mt-4 space-y-4">

                            <li className="flex"><CheckCircle2 size={25}  color='green'/>
                            <span className="ml-3 text-sm">{credits} credits</span></li>

                            <li className="flex"><CheckCircle2 size={25}  color='green'/>
                            <span className="ml-3 text-sm">Powered by GPT-4 (more accurate)</span></li>

                            <li className="flex"><CheckCircle2 size={25}  color='green'/>
                            <span className="ml-3 text-sm">Add Link to Pool (1 credit)</span></li>

                            <li className="flex"><CheckCircle2 size={25}  color='green'/>
                            <span className="ml-3 text-sm">Quizz (1 credits) </span></li>

                            <li className="flex"><CheckCircle2 size={25}  color='green'/>
                            <span className="ml-3 text-sm">Analytics on the links</span></li>
                        </ul>   



                    </div>
        

                    <button
                        type="button" className="bg-green-700 text-white  mt-8 py-3 px-6 border border-transparent rounded-md text-center font-medium "
                    >
                         {isLoggedIn && price ===0 ? 'Already Subscribed' : 'Subscribe'}

                    </button>
                </div>
            </div>


        </div>
    )
}

export default Card