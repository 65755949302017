import React, { useState } from 'react'
import request from '../../request/request';
import { useDeleteContext } from '../../context/DeleteContext';
import './style.css'
import { Trash2 } from 'lucide-react';
import Modal from '../Modal/Modal';
import { useBacklinks } from '../../context/BacklinksContext';
import { useAuth } from '../../context/AuthContext';


interface RemoveLink {
  text: string,
  id: string,
  setDeleteStatus: React.Dispatch<React.SetStateAction<boolean>>,
  deleteStatus: boolean
}

const RemoveLink: React.FC<RemoveLink> = ({ text, id, setDeleteStatus, deleteStatus }) => {

  const { setDeleteStatusText } = useDeleteContext();
  const { _addBacklinks } = useBacklinks();
  const [open, setOpen] = useState(false)
  const {_logout} = useAuth();

  const handleDelete = async (id: string) => {


    const response = await request.deleteLink(id);

    if (response.status === 401) {

      _logout();
    }  else {

      setDeleteStatusText(response.data.message);

      setDeleteStatus(!deleteStatus);

      _addBacklinks([], '');
    }

    setTimeout(() => {
      setDeleteStatusText(null);
    }, 3000);

  }

  return (
    <>
      <button className='btn-icon-text-align bg-red-500 hover:bg-red-700 text-white py-1 px-1 rounded' onClick={() => setOpen(true)}>
        <Trash2 size={15} /> {text}
      </button>


      <Modal open={open} onClose={() => setOpen(false)}>
        <div className="text-center w-45">
          <Trash2 size={40} className="mx-auto text-red-500" />
          <div className="mx-auto my-4 w-48">
            <h3 className="text-lg font-black text-gray-800">Confirm Delete</h3>
            <p className="text-sm text-gray-500">
              Are you sure you want to delete this link?
            </p>
          </div>
          <div className="flex gap-4">
            <button className="bg-red-500 hover:bg-red-700 w-full text-white py-1 px-1 rounded" onClick={() => handleDelete(id)}>Delete</button>
            <button
              className="bg-green-500 hover:bg-green-700 w-full text-white py-1 px-1 rounded"
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default RemoveLink