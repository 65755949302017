import { useLocation } from 'react-router-dom';

type Props = {}

const Footer = (props: Props) => {

  const location = useLocation();

  const shouldRenderNavbar = !location.pathname.startsWith('/dashboard');


  return shouldRenderNavbar ? (
    <>
    {/* // <div className='text-sm font-semibold text-slate-500 flex justify-center items-center mt-auto mb-2'>Developed by Akshay</div> */}
    
    <footer className=" text-gray-700 mt-auto py-6">
    <hr />
      <div className="mt-4 container mx-auto text-center">
        <p>© 2024 Backlink Tracker. All rights reserved.</p>
      </div>
    </footer>
    </>



  ) : null;
}

export default Footer;