import React, { createContext, useContext, useState } from 'react';
import { useBacklinks } from './BacklinksContext';
import Cookies from 'js-cookie';


interface AuthProviderProps {
  children: React.ReactNode;
}

interface UserData {
  email: string
  name: string
  token: string
  total_credits: number
  emailNotification: boolean
  automatedEmail: boolean
}

interface AuthContextTypes {

  isLoggedIn: boolean | undefined
  _login: (userdata: UserData) => void
  _logout: () => void
  _updateCredits: (newCredits: number) => void
  username: string
  credits: number
  email: string
  automatedEmail: boolean
  emailNotification: boolean

}

const AuthContext = createContext<AuthContextTypes | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {


  const [isLoggedIn, setIsLoggedIn] = useState(!!Cookies.get('jwt-token'));
  const [username, setUsername] = useState(Cookies.get('username') || '');
  const [credits, setCredits] = useState(parseInt(Cookies.get('total_credits') || '0'));
  const [email, setEmail] = useState(Cookies.get('email') || '');
  const [emailNotification, setEmailNotification] = useState(Cookies.get('emailNotification') === 'true');
  const [automatedEmail, setAutomatedEmail] = useState(Cookies.get('automatedEmail') === 'true')

  const { _addBacklinks } = useBacklinks();


  const _login = (userdata: UserData) => {
   
    if (userdata.token) {
      Cookies.set('jwt-token', userdata.token, { expires: 1 });
    }

    setIsLoggedIn(true);

    Cookies.set('username', userdata.name);
    setUsername(userdata.name);
    Cookies.set('total_credits', userdata.total_credits.toString());
    setCredits(userdata.total_credits);

    Cookies.set('email', userdata.email);
    setEmail(userdata.email);

    Cookies.set('emailNotification', userdata.emailNotification.toString());
    setEmailNotification(userdata.emailNotification);

    Cookies.set('automatedEmail', userdata.automatedEmail.toString());
    setAutomatedEmail(userdata.automatedEmail);
  };

  const _logout = () => {
    Cookies.remove('jwt-token');
    setIsLoggedIn(false);
    _addBacklinks([], '');

  };

  const _updateCredits = (newCredits: number) => {
    Cookies.set('total_credits', newCredits.toString());
    setCredits(newCredits);
  };

  console.log("auth context render");
  

  return (
    <AuthContext.Provider value={{ isLoggedIn, _login, _logout, username, credits, _updateCredits, email, emailNotification, automatedEmail }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextTypes => {

  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
};
