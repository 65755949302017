import React from 'react'
import HeroSection from './HeroSection';
import './style.css'
import Navbar from '../../components/Navbar/Navbar';
import Subscription from '../../components/Subscription/Subscription';
import HowItWorks from './HowItWorks';
import downImg from './down.png'
import downImg2 from './down2.png'
import Stats from '../../components/HomeComponents/Stats';


type Props = {}

const HomePage = (props: Props) => {

  return (
    <div className='homepage-main'>
        {/* <Navbar /> */}
        <HeroSection />
        <Stats />
        <img src={downImg}/>
        <HowItWorks />
        <img src={downImg2}/>
        <Subscription />
      
    </div>
  )
}

export default HomePage;