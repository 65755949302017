import ChangePassword from '../Forms/ChangePassword'
import AccountSettings from '../Forms/AccountSettings'
import './style.css'

type Props = {}

function Profile({ }: Props) {


  return (
     <div className="flex flex-col md:flex-row mt-8 mb-8">

        <div className="w-full md:w-1/2 mb-4 md:mb-0">
          {/* Left component */}
            <AccountSettings />
        </div>


        <div className="w-full md:w-1/2 mb-4 md:mb-0">
          {/* Right component */}
            <ChangePassword />
        </div>
    </div>
  )
}

export default Profile