import React, { memo, useState } from 'react'
import FallingLinesLoader from '../Loader/FallingLinesLoader';
import request from '../../request/request';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { validateChangePassword } from '../../utils/validationUtils';
import Success from '../Messages/Success';

interface ChangePasswordData {
    new_password: string,
    confirm_password: string
}

interface ValidationChangePasswordError {
    errorNewPassword?: string;
    errorConfirmPassword?: string;
}


const ChangePassword: React.FC = () => {

    let [error, setError] = useState<ValidationChangePasswordError>({
        errorNewPassword: "",
        errorConfirmPassword: ""
    });

    const [data, setData] = useState<ChangePasswordData>({ new_password: "", confirm_password: "" });
    const [regerror, setRegError] = useState<string | null>(null);
    const [loader, setLoader] = useState<boolean>(false);

    const { _logout } = useAuth();
    const navigate = useNavigate();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setData((prevData) => ({
            ...prevData,
            [event.target.name]: event.target.value,
        }))
    }


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

        console.log(data);

        event.preventDefault();

        setLoader(true);

        try {

            const validationError: ValidationChangePasswordError = validateChangePassword(data);

            if (Object.keys(validationError).length === 0) {

                setError({
                    errorNewPassword: "",
                    errorConfirmPassword: ""
                });

                const response = await request.changePassword(data);

                setLoader(false);

                if (response.data.status === 'ok') {

                    _logout();
                    navigate('/login');
                    setRegError("");

                } else {

                    setRegError(response.data.message);
                    setTimeout(() => {
                        setRegError(null);
                    }, 4000);
                }
            } else {
                setError(validationError);
            }

        } catch (error) {

            setRegError("An error occurred while changing password");
        } finally {
            setLoader(false);
        }
    }

    console.log("change password rendering");
    


    return (
        <>

            <div className=" mx-auto p-8 ">
                <h1 className="text-2xl text-left mb-1 text-gray-600">
                    CHANGE PASSWORD
                </h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="email" className="text-sm font-semibold text-gray-600">
                            New Passowrd
                        </label>
                        <input
                            id="new_password"
                            name="new_password"
                            type="password"
                            placeholder="Enter New Password"
                            onChange={handleChange}
                            className="mt-1 p-2 w-full border rounded focus:outline-none focus:border-green-500"
                            autoComplete="email"
                        />
                        <span className="text-xs text-red-500">{error.errorNewPassword}</span>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="password" className="text-sm font-semibold text-gray-600">
                            Confirm Passowrd
                        </label>
                        <input
                            id="confirm_password"
                            name="confirm_password"
                            type="password"
                            placeholder="Confirm Password"
                            onChange={handleChange}
                            className="mt-1 p-2 w-full border rounded focus:outline-none focus:border-green-500"
                            autoComplete="current-password"
                        />
                        <span className="text-xs text-red-500">{error.errorConfirmPassword}</span>
                    </div>


                    {regerror !== null ? <div className="bg-red-100 text-red-500 p-2 mb-4">{regerror}</div> : null}
                    {loader && <FallingLinesLoader />}

                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className="bg-green-700 text-white text-sm p-2 rounded hover:bg-green-600 focus:outline-none focus:shadow-outline-green"
                        >
                            Change Password
                        </button>
                    </div>


                </form>
            </div>
        </>

    )
}

export default memo(ChangePassword)