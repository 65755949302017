import React from 'react'
import Card from './Card/Card'
import './style.css'

const Subscription: React.FC = () => {

    return (
        <>
            <div>
                <h2 className="text-3xl font-bold tracki text-center mt-12 sm:text-5xl hero-title ">Pricing</h2>
                <p className="max-w-3xl mx-auto mt-4 text-xl text-center ">Get started on our free plan and upgrade when you are
                    ready.</p>
            </div>

            <div className="relative">
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                    <p className="text-slate-600 text-2xl font-bold z-10">Comming Soon</p>
                </div>
                <div className='blur-sm subscriptions'>

                    <Card price={0} credits={10} duration={'month'} plan={'Starter'} />
                    <Card price={4.99} credits={50} duration={'month'} plan={'Pro'} />
                    <Card price={55.99} credits={600} duration={'year'} plan={'Pro'} />
                </div>
            </div>


        </>
    )
}

export default Subscription