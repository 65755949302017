import React from 'react'

interface GeneralSubButtonProp {
    btnText: string
    onClick: ()=> void
}
const GeneralSubButton: React.FC<GeneralSubButtonProp> = ({ btnText, onClick }) => {

    return (
        <>
            <button type="button" className='subbtn' onClick={onClick}>
                {btnText}
            </button>
        </>
    )
}

export default GeneralSubButton