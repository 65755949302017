import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import request from "../../request/request";
import { useAuth } from "../../context/AuthContext";
import FallingLinesLoader from "../Loader/FallingLinesLoader";

interface LoginData {
  email: string,
  password: string
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [errorEmail, setErrorEmail] = useState<string>("");
  const [errorPassword, setErrorPassword] = useState<string>("");
  const [data, setData] = useState<LoginData>({ email: "", password: "" });
  const [regerror, setRegError] = useState<string | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { _login } = useAuth();


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    setData((prevData) => ({
      ...prevData,
      [event.target.name]: event.target.value,
    }));

  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!data.email || !data.password) {
      setErrorPassword("Email and password are required");
      return
    }

    //clear previous error

    setLoader(true);

    try {

      const response = await request.login(data);

      setLoader(false);

      if (response.data.status === 'ok') {
        sessionStorage.removeItem('token'); // Removing already existed token in case user relogs in
        _login(response.data.user); // Adding to context
        navigate('/dashboard');
        setErrorEmail("");
        setErrorPassword("");
        setRegError("");

      } else {

        setRegError(response.data.message);
        setTimeout(() => {
          setRegError(null);
        }, 4000);
      }

    } catch (error) {
      setRegError("An error occurred during login");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    // Focus the input element on mount
    if(!inputRef.current){
      return
    }
    
    inputRef.current.focus();
  }, []);

  return (
    <>
      <h1 className="text-3xl font-bold text-center mb-8 mt-8 text-gray-800">
        Sign In
      </h1>
      <div className="container mx-auto max-w-md bg-white p-8 rounded shadow-md">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="email" className="text-sm font-semibold text-gray-600">
              Email-Id
            </label>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Enter Your Email-Id"
              onChange={handleChange}
              className="mt-1 p-2 w-full border rounded focus:outline-none focus:border-green-500"
              autoComplete="email"
              ref={inputRef}
            />
            <span className="text-xs text-red-500">{errorEmail}</span>
          </div>

          <div className="mb-4">
            <label htmlFor="password" className="text-sm font-semibold text-gray-600">
              Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              placeholder="Enter Your Password"
              onChange={handleChange}
              className="mt-1 p-2 w-full border rounded focus:outline-none focus:border-green-500"
              autoComplete="current-password"
            />
            <span className="text-xs text-red-500">{errorPassword}</span>
          </div>

          <p className="text-gray-600 text-sm mb-4">
            Don't have an account? <Link to="/register" className="text-green-500">Sign Up</Link>
          </p>

          {regerror !== null ? <div className="bg-red-100 text-red-500 p-2 mb-4">{regerror}</div> : null}
          {loader && <FallingLinesLoader />}

          <button
            type="submit"
            className="w-full bg-green-700 text-white p-2 rounded hover:bg-green-600 focus:outline-none focus:shadow-outline-green"
          >
            Login
          </button>

         
        </form>
      </div>
    </>
  );
};

export default Login;
