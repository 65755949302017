import React, { useState } from 'react'
import './style.css'
import request from '../../request/request'
import { useBacklinks } from '../../context/BacklinksContext';
import FallingLinesLoader from '../Loader/FallingLinesLoader';
import Failure from '../Messages/Failure';
import { useAuth } from '../../context/AuthContext';


interface LinksData {
    backlinks: string[],
    sponsoredLink: string,
    user: string,
    _id: string
}

interface LinkAvailabilityDetailsProps {
    text: string,
    backlinks: LinksData
}

interface KeywordsDataTypes {
    keyword: string,
    found: boolean
}

const LinkAvailabilityDetails: React.FC<LinkAvailabilityDetailsProps> = ({ text, backlinks }) => {

    const [keywordsData, setKeywordsData] = useState<KeywordsDataTypes[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const { _addBacklinks, _loaderToTrack } = useBacklinks();
    const {_logout } = useAuth();

    const handlePoolButton = async () => {

        try {

            if (keywordsData.length === 0) {

                setLoading(true);
                _loaderToTrack(true)
            }

            const response = await request.checkAvailability(backlinks);

            setLoading(false);

            console.log(response);

            _loaderToTrack(false)

            if (response.status === 401) {
                
                _logout();

            } else if (response.status === 500 || response.status === 404) {

                setError("Sponsored post is not valid");

                setTimeout(() => {
                    setError(null);
                }, 3000);

            } else {

                setKeywordsData(response.data);
                _addBacklinks(response.data, backlinks.sponsoredLink);
            }

        } catch (err) {
            console.log(err);

        }

    }

    return (
        <div className='result-and-btn'>
            <div className='linkpool-btn' onClick={handlePoolButton}>

                {loading ? 'Scanning..' : text}

            </div>

            {loading && <FallingLinesLoader />}

            {
                keywordsData.length > 0 &&
                <>
                    <p className='data-para-keywords'>Total Backlinks: {keywordsData.length}</p>

                    <p className={keywordsData.filter(keyword => keyword.found).length <= 0 ? 'data-para-keywords-nt' : 'data-para-keywords-found'}>Backlinks Found: {keywordsData.filter(keyword => keyword.found).length}</p>
                </>

            }

            {error && <div className='error'>{error} </div>}


        </div>


    )
}

export default LinkAvailabilityDetails