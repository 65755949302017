import React, { useState } from 'react'
import LinkTrackerSection from '../LinkTracker/LinkTrackerSection'
import './DashboardSection.css'
import { useAuth } from '../../context/AuthContext';
import { Navigate, useNavigate } from 'react-router-dom';
import Sidebar, { SidebarItem } from '../Sidebar/Sidebar';
import { LifeBuoy, Receipt, Package, BarChart3, LayoutDashboard, LogOut, CircleDollarSign } from 'lucide-react';
import Subscription from '../Subscription/Subscription';
import BulkCheck from '../BulkCheck/BulkCheck';
import Profile from '../Profile/Profile';

interface ComponentMappingInterface {

  [key: string]: JSX.Element  //every key in that object has value react component
}

const DashboardSection: React.FC = () => {

  const navigate = useNavigate();

  const { isLoggedIn, credits, _logout } = useAuth();
  const [selectedComponent, setSelectedComponent] = useState<string>('LinkTrackerSection');


  const handleSidebarItemClick = (component: string) => {
    setSelectedComponent(component);
  };

  const handleLogout = () => {

    if (isLoggedIn) {

      _logout();

      navigate('/login');

    } else {

      navigate('/login')
    }
  }

  if (!isLoggedIn) {
    // Redirect to login if not logged in
    return <Navigate to="/login" />;
  }

  const componentMapping: ComponentMappingInterface = {
    LinkTrackerSection: <LinkTrackerSection />,
    Subscription: <Subscription />,
    BulkCheck: <BulkCheck />,
    Profile: <Profile />
    // Add more mappings as needed
  };

  return (
    <>
      <div className='dashboard-container'>
        <div className='split-1'>
          <Sidebar>
            <SidebarItem icon={<LayoutDashboard size={20} />}
              text="Dashboard"
              active={selectedComponent === 'LinkTrackerSection'}
              onClick={() => handleSidebarItemClick('LinkTrackerSection')}
            />

            <SidebarItem
              icon={<BarChart3 size={20} />}
              text="Bulk Analysis"
              active={selectedComponent === 'BulkCheck'}
              onClick={() => handleSidebarItemClick('BulkCheck')}
            />

            <SidebarItem
              icon={<CircleDollarSign size={20} />}
              text="Subscription"
              active={selectedComponent === 'Subscription'}
              onClick={() => handleSidebarItemClick('Subscription')}
            />

            <SidebarItem
            icon={<Package size={20} />}
            text="Profile"
            active={selectedComponent === 'Profile'}
            onClick={() => handleSidebarItemClick('Profile')} />

            <SidebarItem icon={<Receipt size={20} />} text={`Credits - ${credits}`} />

            <hr className='my-3' />

            <SidebarItem icon={<LifeBuoy size={20} />} text="Help" />

            <hr className='my-3' />

            <SidebarItem icon={<LogOut size={20} />} text="Logout" onClick={handleLogout} />


          </Sidebar>
        </div>
        <div className='split-2'>
          {/* Render the selected component */}
          {componentMapping[selectedComponent] || <LinkTrackerSection />}
        </div>
      </div>
    </>
  )
}

export default DashboardSection