import { useEffect, useState } from 'react'
import './style.css'
import { Link, Link2, UsersRound } from 'lucide-react'
import request from '../../request/request'

interface StatsTypes {
    totalBacklinks: number,
    totalSponsoredLinks: number,
    totalUsers: number
}

const Stats: React.FC = () => {

    const [stats, setStats] = useState<StatsTypes | undefined>();

    const getSatistics = async () => {

        try {
            const response = await request.getStats();
            setStats(response.data)
            console.log(response.data);

        }catch(error){
            console.log(error);
            
        }
    }

    useEffect(() => {

        getSatistics();
    }, [])

    console.log('stats rendering');


    return (
        <>
            <div className='hero-section'>
                <div className='stats-row'>
                    <div className='stats-box'>
                        <UsersRound color='#10B981' size={45}></UsersRound>
                        {/* {stats?.totalUsers ?? 'Loading...'} */}
                        <div className='hero-title'>522</div>
                        <p>Total Registered Users</p>
                    </div>
                    <div className='stats-box'>
                        <Link2 color='#10B981' size={45}></Link2>
                        <div className='hero-title'>1265</div>
                        <p>Total Registered Links</p>
                    </div>
                    <div className='stats-box'>
                        <Link color='#10B981' size={45}></Link>
                        <div className='hero-title'>3254</div>
                        <p>Total Backlinks</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Stats