interface RegistrationData {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
}

interface ValidationError {
  errorName?: string;
  errorEmail?: string;
  errorPassword?: string;
  errorRePassword?: string;
}

interface ChangePasswordData {
  new_password: string;
  confirm_password: string;
}

interface ValidationChangePasswordError {
  errorNewPassword?: string;
  errorConfirmPassword?: string;
}

const nameregx = /^([a-z]+(-| )?)+$/i;
const emailregx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const passwordregx = /^(?=.*\d)(?=.*[!@#$%^_&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

export const validate = (data: RegistrationData): ValidationError => {
  let error: ValidationError = {};

  // Validation

  if (!data.name) {
    error.errorName = "*Name is required";
  } else if (!data.name.match(nameregx)) {
    error.errorName = "*Invalid name";
  }

  if (!data.email) {
    error.errorEmail = "*Email is required";
  } else if (!data.email.match(emailregx)) {
    error.errorEmail = "*Invalid email";
  }
  if (!data.password) {
    error.errorPassword = "*Password is required";
  } else if (!data.password.match(passwordregx)) {
    error.errorPassword = "*Password must be like Abcde@123";
  }

  if (!data.confirmPassword) {
    error.errorRePassword = "*Confirm password is required";
  } else if (data.password !== data.confirmPassword) {
    error.errorRePassword = "*Password does not match";
  }

  return error;
};

export const validateChangePassword = (
  data: ChangePasswordData
): ValidationChangePasswordError => {
  let error: ValidationChangePasswordError = {};

  // Validation

  if (!data.new_password) {
    error.errorNewPassword = "*Password is required";
  } else if (!data.new_password.match(passwordregx)) {
    error.errorNewPassword = "*Password must be like Abcde@123";
  }

  if (!data.confirm_password) {
    error.errorConfirmPassword = "*Confirm password is required";
  } else if (data.confirm_password !== data.new_password) {
    error.errorConfirmPassword = "*Password does not match";
  }

  return error;
};
