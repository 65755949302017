import React from 'react';
import { Pie } from 'react-chartjs-2';

interface BacklinkResult {
    backlink: string;
    found: boolean;
  }
  
interface LinkResult {
    sponsoredLink: string;
    backlinkResults: BacklinkResult[];
  }

interface PieChartProps {
  bulkCheckResults: LinkResult[];
}


const PieChart: React.FC<PieChartProps> = ({ bulkCheckResults }) => {


  // Calculate the number of found and not found backlinks
  const foundCount = bulkCheckResults.reduce((total, linkResult) => {
    return total + linkResult.backlinkResults.filter(backlink => backlink.found).length;
  }, 0);
  const notFoundCount = bulkCheckResults.reduce((total, linkResult) => {
    return total + linkResult.backlinkResults.filter(backlink => !backlink.found).length;
  }, 0);



  // Data for the pie chart
  const data = {

    labels: ['Found', 'Not Found'],

    datasets: [

      {
        data: [foundCount, notFoundCount],
        backgroundColor: ['#98D594', '#FCAB8F'],
        hoverBackgroundColor: ['#0B6121', '#B40404']
      }
    ]
  };

  
  const options = {
    plugins: {
        tooltip: {
            enabled: true,
            callbacks: {
                label: function(context: any) {
                    // Customize tooltip label to include segment label and value
                    const label = data.labels[context.dataIndex];                    
                    const value = data.datasets[0].data[context.dataIndex];
                    return `${label}: ${value} backlinks`;
                }
            }
        }
    },
      // Adjust size of the pie chart
      responsive: true,
      maintainAspectRatio: false,
      width: 600, // Specify the width in pixels
      height: 600 // Specify the height in pixels
};
  
  

  return <Pie data={data} options={options} />;
};

export default PieChart;
