import React from 'react'

interface ToggleTypes {

    isChecked: boolean;
    handleToggle: () => void;
}

const ToggleButton: React.FC<ToggleTypes>=({isChecked, handleToggle}) => {
    return (

        <div>

            <label className='themeSwitcherTwo relative inline-flex cursor-pointer select-none items-center'>
                <input
                    type='checkbox'
                    checked={isChecked}
                    onChange={handleToggle}
                    className='sr-only'
                />
                
                <span
                    className={`slider mx-4 flex h-6 w-[50px] items-center rounded-full p-0.5 duration-300 ${isChecked ? 'bg-[#288a39]' : 'bg-[#CCCCCE]'
                        }`}
                >
                    <span
                        className={`dot h-5 w-5 rounded-full bg-white duration-200 ${isChecked ? 'translate-x-[26px]' : ''
                            }`}
                    ></span>
                </span>
                
            </label>

        </div>
    )
}

export default ToggleButton