import React from 'react'

interface LinkSubmitButtonProp {
    children: () => void;
}
const LinkSubmitButton: React.FC<LinkSubmitButtonProp> = ({ children }) => {

    return (
        <>
            <button type="button" className='subbtn' onClick={children}>
                Submit to Pool
            </button>
        </>
    )
}

export default LinkSubmitButton