import React, { memo, useEffect, useState } from 'react'
import './style.css'
import { Check, Link2, X } from 'lucide-react'
import { useBacklinks } from '../../context/BacklinksContext'
import TruncateString from '../TruncateString/TruncateString';


interface BacklinksKeysToMap {
  keyword: string,
  found: boolean
}

const BacklinksDetail: React.FC = () => {

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);


  const { backlinks } = useBacklinks();

  console.log("rendering backlinkdetails");

  
  useEffect(() => {

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [windowWidth])


  return (

    <>
      <div className='mt-6'>
        <div className='detail-container'>

          <div className='sponsored-link-detail'>
            <Link2 className='mr-3' />

            {backlinks.sponsoredLink && (
              <div className='sponsored-link-single'>


                <TruncateString originalString={backlinks?.sponsoredLink} maxLength={60} />

              </div>

            )}


          </div>
          <hr className='h-px my-1 bg-gray-200 border-0 dark:bg-gray-700' />

          <div className='links-details-both'>

            <div className='left-title-availabilty-1' >
              Backlinks Found

            </div>

            <div className='right-link--details'>

              {backlinks?.backlinksArray && backlinks?.backlinksArray.map(

                (backlink: BacklinksKeysToMap, index: number) => backlink.found ? (
                  <>
                    <div className='right-content' key={index}>

                    {windowWidth > 742 && <Check className='mr-3' color='green' size={20} /> }

                      <TruncateString originalString={backlink.keyword} maxLength={100} />

                    </div>
                    <hr className='h-px my-1 bg-gray-200 border-0 dark:bg-gray-700' />
                  </>

                ) : null
              )}

            </div>

          </div>


          <hr className='h-px my-1 bg-gray-200 border-0 dark:bg-gray-700' />


          <div className='links-details-both'>

            <div className='left-title-availabilty-2'>
              Not Found

            </div>
            <div className='right-link--details'>

              {backlinks.backlinksArray && backlinks?.backlinksArray.map(

                (backlink: BacklinksKeysToMap, index: number) => !backlink.found ? (
                  <>
                    <div className='right-content' key={index}>

                    {windowWidth > 742 &&   <X className='mr-3' color='red' size={20} /> }

                      <div className='backlink-details-wrap'><TruncateString originalString={backlink.keyword} maxLength={100} />
                      </div>
                    </div>
                    <hr className='h-px my-1 bg-gray-200 border-0 dark:bg-gray-700' />
                  </>
                ) : null
              )}

            </div>
          </div>


        </div>
      </div>

    </>

  )
}

export default memo(BacklinksDetail);