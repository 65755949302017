import { useState } from 'react';
import request from '../../request/request';
import GeneralSubButton from '../Buttons/GeneralSubButton';
import LinkPool from '../LinkPool/LinkPool';
import './style.css'
import TruncateString from '../TruncateString/TruncateString';
import PieChart from './Pie';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js'
import FallingLinesLoader from '../Loader/FallingLinesLoader';
import { useAuth } from '../../context/AuthContext';


// Define TypeScript interfaces for data structures
interface BacklinkResult {
  backlink: string;
  found: boolean;
}

interface LinkResult {
  sponsoredLink: string;
  backlinkResults: BacklinkResult[];
}


const BulkCheck: React.FC = () => {

  const [bulkCheckResults, setBulkCheckResults] = useState<LinkResult[]>([]);
  const [loader, setLoader] = useState<boolean>(false);

  const {_logout} = useAuth();

  Chart.register(ArcElement, Tooltip, Legend);


  console.log("bulk check rendering");
  

  const handleBulkCheck = async () => {

    setLoader(true);
    try {

      const response = await request.bulkCheck();
      console.log(response);
      
      if (response.status === 401) {
        _logout();

      } else {

        setLoader(false);
      }

      setBulkCheckResults(response.data);

    } catch (error) {
      console.log(error);

    } finally {
      setLoader(false);
    }

  }

  return (

    <div>
      <div>
        <h2 className="text-3xl font-bold tracki text-center mt-12 sm:text-5xl hero-title ">Bulk Analysis</h2>
        <p className="max-w-3xl mx-auto mt-4 text-xl text-center mb-5">Analyze all your links with just one click
        </p>
      </div>

      <div className='flex flex-col justify-center gap-2'>
        <LinkPool showCheckAvailability={false} />
        <div className='submit-btn'>
          <GeneralSubButton btnText='Start Analysis' onClick={handleBulkCheck} />
        </div>
      </div>

      {loader && <FallingLinesLoader />}

      {bulkCheckResults.length > 0 &&

        <div className='bulkcheck-result-container'>

          <div className='bulkcheck-result-dataset'>

            <div className="container mx-auto py-8">

              {bulkCheckResults.map((linkResult, index) => (

                <div key={index} className="my-4">

                  <h3 className="text-base font-semibold"><TruncateString originalString={linkResult.sponsoredLink} maxLength={55} /></h3>
                  <table className="w-full mt-2 border-collapse border border-gray-300 bg-white">
                    <thead>
                      <tr className="bg-gray-100">
                        <th className="px-4 py-2 text-sm">Backlinks</th>
                        <th className="px-4 py-2 text-sm">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {linkResult.backlinkResults.map((backlinkResult, index) => (

                        <tr key={index} className="border-b border-gray-200">

                          <td className="px-2 py-2 text-sm"><TruncateString originalString={backlinkResult.backlink} maxLength={35} /></td>

                          <td className="px-2 py-2 text-sm">
                            <span className={backlinkResult.found ? 'text-green-500' : 'text-red-500'}>
                              {backlinkResult.found ? 'Found' : 'Not Found'}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
            </div>

          </div>

          <div className='bulkcheck-charts'>

            {bulkCheckResults &&

              <div className="container mx-auto py-8">

                <div className="chart-container">
                  <PieChart bulkCheckResults={bulkCheckResults} />
                </div>

              </div>
            }

          </div>

        </div>


      }

    </div>
  )
}

export default BulkCheck;