import React from 'react'
import './Navbar.css'
import { useLocation, useNavigate } from 'react-router-dom'

const Navbar: React.FC = () => {

  // const { isLoggedIn, _logout, username } = useAuth();
  const navigate = useNavigate();

  const location = useLocation();

  const shouldRenderNavbar = !location.pathname.startsWith('/dashboard');


  const habdleLoginLogout = () => {
    navigate('/login')

  }

  return shouldRenderNavbar ? (

    <div className='navbar'>

      <div className='logo' onClick={()=> navigate('/')}>
        BL Tracker
      </div>

      <div className='mid-menu'>

        <div className='sub-mid-menu'>
          Product
        </div>
        <div className='sub-mid-menu'>
          About
        </div>

        <div className='sub-mid-menu'>
          Pricing
        </div>

      </div>


      <div className='get-started bg-emerald-500' onClick={habdleLoginLogout}> Login </div>
    </div>
    ) : null
  
}

export default Navbar