import axios, { AxiosResponse } from "axios";
import Cookies from "js-cookie";
const BASE_URL = process.env.REACT_APP_BASE_URL;
axios.defaults.baseURL = BASE_URL;

interface backLinkDataTypes {
  sponsoredLink: string | null;
  backlinks: string[];
}

interface registerUserTypes {
  name: string;
  email: string;
  password: string;
}

interface loginUserTypes {
  email: string;
  password: string;
}

interface changePasswordTypes {
  new_password: string;
  confirm_password: string;
}

interface userDataToUpdateTypes {
  email: string;
  name: string;
  emailNotification: boolean;
  automatedEmail: boolean;
}

const request = {
  addLinks: async (backLinkData: backLinkDataTypes): Promise<AxiosResponse> => {
    try {
      const response = await axios.post("/addLinks", backLinkData, {
        headers: {
          Authorization: "Bearer " + Cookies.get("jwt-token"),
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (error: any) {
      return error.response as AxiosResponse;
    }
  },

  getLinks: async (): Promise<AxiosResponse> => {
    try {
      const response = await axios.get("/linkById", {
        headers: {
          Authorization: "Bearer " + Cookies.get("jwt-token"),
          "Content-Type": "application/json",
        },
      });

      return response;
    } catch (error: any) {
      return error.response as AxiosResponse;
    }
  },

  deleteLink: async (id: string) => {
    try {
      const response = await axios.delete(`/links/${id}`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("jwt-token"),
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (error: any) {
      return error.response as AxiosResponse;
    }
  },

  checkAvailability: async (backlinks: backLinkDataTypes) => {
    try {
      const response = await axios.post("/checkAvailability", backlinks, {
        headers: {
          Authorization: "Bearer " + Cookies.get("jwt-token"),
          "Content-Type": "application/json",
        },
      });

      return response;
    } catch (error: any) {
      return error.response as AxiosResponse;
    }
  },

  register: async (user: registerUserTypes) => {
    try {
      const response = await axios.post("/register", user);
      return response;
    } catch (error: any) {
      return error.response as AxiosResponse;
    }
  },

  login: async (user: loginUserTypes) => {
    console.log(user);
    try {
      const response = await axios.post("/login", user);
      return response;
    } catch (error: any) {
      return error.response as AxiosResponse;
    }
  },

  bulkCheck: async (): Promise<AxiosResponse> => {
    try {
      const response = await axios.get("/bulkCheck", {
        headers: {
          Authorization: "Bearer " + Cookies.get("jwt-token"),
        },
      });

      return response;
    } catch (error: any) {
      return error.response as AxiosResponse;
    }
  },

  changePassword: async (data: changePasswordTypes) => {
    try {
      const response = await axios.post("/change-password", data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("jwt-token"),
        },
      });
      return response;
    } catch (error: any) {
      return error.response as AxiosResponse;
    }
  },

  updateProfile: async (data: userDataToUpdateTypes) => {
    try {
      const response = await axios.put("/update-profile", data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("jwt-token"),
        },
      });
      return response;
    } catch (error: any) {
      return error.response as AxiosResponse;
    }
  },

  getStats: async () => {
    try {
      const response = await axios.get("/getStats");

      return response;
      
    } catch (error: any) {
      return error.response as AxiosResponse;
    }
  },
};

export default request;
