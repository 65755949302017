import React from 'react'
import './style.css'
import { Link, Megaphone, SearchCheck } from 'lucide-react';

const HowItWorks: React.FC = () => {

    return (

        <div className='hero-section flex-wrap mt-5 mb-5'>
            <div className='hero-title'>
                How it Works
            </div>

            <div className='cards-in-howitowrk'>

                <div className='how-card'>
                <div className='how-card-title'><Link color='#10B981'/> Monitor Backlink</div>
                    <div className='how-card-desc'>
                        Effortlessly monitor and protect your backlinks with our platform. We notify you if any link is deleted
                    </div>
                </div>

                <div className='how-card'>
                    <div className='how-card-title'><SearchCheck color='#10B981'/>Bulk Checking</div>
                        <div className='how-card-desc'>
                            Effortlessly monitor and protect your backlinks with our platform. We notify you if any link is deleted
                        </div>
                    </div>
                
                <div className='how-card'>
                <div className='how-card-title'><Megaphone color='#10B981'/>Email Alert</div>
                    <div className='how-card-desc'>
                        Effortlessly monitor and protect your backlinks with our platform. We notify you if any link is deleted
                    </div>
                </div>
                


            </div>

        </div>

    )
}

export default HowItWorks;