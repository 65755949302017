import { useState, useEffect } from 'react';

const useNetworkStatus =():boolean => {

  const [isOnline, setOnlineStatus] = useState<boolean>(navigator.onLine);

  const handleOnlineStatusChange = () => {
    setOnlineStatus(navigator.onLine);
  };

  useEffect(() => {
    // Add event listeners to track changes in network status
    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);

    // Clean up event listeners when the component is unmounted
    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, []);

  return isOnline;
};

export default useNetworkStatus;
